.offer-tabs {
  color: #000f;
  font-weight: bold;
  position: absolute;
  z-index: 1500;
  left: auto;
  top: 5.4rem;
}

.content {
  position: relative;
  top: 10rem;
  box-shadow: none;
  background-color: #f5f5f5;
}

.MuiTab-wrapper {
  font-size: 0.875rem;
  font-weight: bold;
}

.MuiTabs-indicator {
  background-color: #000000 !important;
}