#navbar {

  min-width: 100vw;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.3);

  .navigation-links {
    max-width: 80%;
    justify-content: space-between;
    .brand {
      a:hover {
        text-decoration: none;
        border: none;
      }
    }

    button:hover {
      background-color: #fff;
    }
    
    a {
      text-decoration: none;
      color: #666666;
      font-weight: bold;
      border-bottom: 3px solid #ffffff;

      &.active {
        color: #000000;
        border-bottom: 2px solid #000000;
      }
      &:hover {
        color: #000000;
      }
    }
  }
}